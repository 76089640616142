import React, { useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";

const TextInvites = (props) => {
    const { t } = useTranslation()
    const [copied, setCopied] = useState(false);

    const renderPopover = (content) => (
        <Popover>
            <Popover.Body>{`${content}`}</Popover.Body>
        </Popover>
    );
    const copyToClipboard = async (text) => {
        if (navigator && navigator.clipboard) {
            try {
                await navigator.clipboard.writeText(text);
                setCopied(true);
                // Reset copied status after a delay (e.g., 2 seconds)
                setTimeout(() => {
                    setCopied(false);
                }, 2000);
            } catch (error) {
                console.error("Clipboard copy failed:", error);
            }
        } else {
            // Clipboard API is not supported, provide a fallback method
            try {
                const textArea = document.createElement("textarea");
                textArea.value = text;
                document.body.appendChild(textArea);
                textArea.select();
                document.execCommand("copy");
                document.body.removeChild(textArea);
                setCopied(true);
                // Reset copied status after a delay (e.g., 2 seconds)
                setTimeout(() => {
                    setCopied(false);
                }, 2000);
            } catch (error) {
                console.error("Fallback clipboard copy failed:", error);
            }
        }
    };

    return (
        <div className="tab-pane fade active show" id="nav-banner" role="tabpanel" aria-labelledby="nav-text-tab">
            <h4 className="heading_style_1">{t('textInvite')}</h4>
            {props.isLoading ? (
                // loader
                <Skeleton className="banner_image_content_area" />
            ) : (
                <>
                    {props?.data?.map((item, key) => (
                        <>
                            <h6 className="subject">{item.subject}</h6>
                            <textarea key={key} className="banner_image_content_area" value={`<a href='${item.targetUrl}'>${item.content}</a>`} disabled />
                            <OverlayTrigger key={key} trigger={["hover", "focus"]} placement="top" overlay={renderPopover(copied ? "Copied" : "Copy")}>
                                <span>
                                    <button
                                        onClick={() => copyToClipboard(`<a href='${item.targetUrl}'>${item.content}</a>`)}
                                        className="btn btn-primary">
                                        {t('copy')}
                                    </button>
                                </span>
                            </OverlayTrigger>
                        </>
                    ))}
                </>
            )}
        </div>
    )
};

export default TextInvites;