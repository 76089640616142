import DOMPurify from "dompurify";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import { ApiHook } from "../../../hooks/apiHook";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const EmailInvites = (props) => {
    // define
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    const [errorMessage, setErrorMessage] = useState({
        to: null
    });
    const [emails, setEmails] = useState([]);
    // api
    const sendInviteMailMutation = ApiHook.CallSentMailInvite();
    const clearForm = () => {
        setIsModalOpen(false);
        setEmails([]);
        setCurrentItem(null);
        setErrorMessage({
            to: null
        });
    }
    const handleOpenModal = (item) => {
        setCurrentItem(item);
        setIsModalOpen(true);
    };
    const handleCloseModal = () => {
        clearForm();
    };
    const isMailListEmpty = () => {
        if (emails.length === 0) {
            setErrorMessage({
                to: t("this_field_is_required")
            })
            return true
        } else {
            setErrorMessage({
                to: null
            })
            return false
        }
    }
    const handleMailChange = (data) => {
        setEmails(data);
        setErrorMessage({
            to: null
        });
    }
    const sendInviteMail = () => {
        const mailCheck = isMailListEmpty()
        if (!mailCheck) {
            const payload = {
                mailList: emails,
                subject: currentItem?.subject,
                content: currentItem?.content
            }
            sendInviteMailMutation.mutateAsync(payload, {
                onSuccess: (res) => {
                    if (res.data.status) {
                        clearForm();
                        toast.success(t(res.data.data));
                    } else {
                        toast.error(t(res.data.data));
                    }
                }
            });
        }
    }
    return (
        <>
            {props.isLoading ? (
                <div className="tab-pane fade active show" id="nav-email" role="tabpanel" aria-labelledby="nav-email-tab">
                    <h4 className="heading_style_1">
                        <Skeleton width="15%" />
                    </h4>
                    <div className="emailInviteRow">

                        <div className="emailInviteText">
                            <h4><Skeleton width="25%" /></h4>
                            <p>
                                <Skeleton width={500} height={60} />
                            </p>
                            <button type="submit" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#mailinvite" disabled>
                                {t('preview')}
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="tab-pane fade active show" id="nav-email" role="tabpanel" aria-labelledby="nav-email-tab">
                    <h4 className="heading_style_1">{t('emailInvite')}</h4>
                    {props.data.map((item, key) => (
                        <div key={key} className="emailInviteRow">
                            <div className="emailInviteText">
                                <h5 className="subject">{item.subject}</h5>
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(item.content),
                                    }}
                                />
                                <button type="submit" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#mailinvite" onClick={() => handleOpenModal(item)}>
                                    {t('preview')}
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            )}
            {/* modal pop-up*/}
            <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{t('EMAILINVITE')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="mb-3">
                            <label for="recipient-name" className="col-form-label">
                                {t('to')}*
                            </label>
                            <ReactMultiEmail className="" emails={emails} onChange={(data) => handleMailChange(data)} autoFocus
                                getLabel={(email, index, removeEmail) => {
                                    return (
                                        <div data-tag key={index} className="invite-mails-list-container">
                                            <div data-tag-item className="invite-mails-list-item">{email}</div>
                                            <span data-tag-handle style={{ cursor: "pointer" }} onClick={() => removeEmail(index)}>
                                                ×
                                            </span>
                                        </div>
                                    );
                                }}
                            />
                            {errorMessage.to && (
                                <span className="validation-error-message">{errorMessage.to}</span>
                            )}
                        </div>
                        <div className="mb-3">
                            <p className="mb-0">{t('multiailPLaceholder')}</p>
                            <label for="message-text" className="col-form-label">
                                {t('subject')}*
                            </label>
                            <input className="form-control" id="message-text" value={currentItem?.subject} disabled />
                        </div>
                        <div className="mb-3">
                            <label for="message-text" className="col-form-label">
                                {t('message')}*
                            </label>
                            <div
                                className="form-control"
                                id="message-text"
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(currentItem?.content)
                                }}
                                style={{
                                    whiteSpace: 'pre-wrap',
                                    minHeight: '100px',
                                    padding: '6px 12px',
                                    border: '1px solid #ced4da',
                                    borderRadius: '4px',
                                    backgroundColor: '#e9ecef',
                                    cursor: 'default'
                                }}
                            />
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        {t('close')}
                    </Button>
                    <Button variant="primary" onClick={sendInviteMail}>{t('invite')}</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
};

export default EmailInvites;