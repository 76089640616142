import React, { useEffect, useState,useRef } from "react";
import TableContent from "../Common/table/TableContent";
import TablePagination from "../Common/table/TablePagination";
import { ApiHook } from "../../hooks/apiHook";
import SubmitButton from "../Common/buttons/SubmitButton";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { PanZoom } from "react-easy-panzoom";
import Loader from "react-js-loader";
import TreeNodeComponent from "./genealogy-tree/TreeNodeComponent";
import BoardNodeComponent from "./genealogy-tree/BoardNodeComponent";
import { disableBackToParent } from "../../store/reducers/treeReducer";
import { useDispatch, useSelector } from "react-redux";
const BoardViewTable = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const backEnabled = useSelector((state) => state?.tree?.backToParent);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const headers = [t("member_name"), t("placement"), t("sponsor"), t("level")];
  const [level, setlevel] = useState("all");
  const [selectedLevel, setselectedLvel] = useState("all");
  const downlineMember = ApiHook.CallDownlineMembers(
    level,
    props.currentPage,
    itemsPerPage
  );
  const downlineHeader = ApiHook.CallDownlineHead();
  const [selectedUserId, setSelectedUserId] = useState("");
  const [searchUsername, setSearchUsername] = useState("");
  const [doubleUser, setDoubleUser] = useState("");
  const [showSearchLoader, setShowSearchLoader] = useState(false);
  const [activeBookId,setActivebookId] =useState(1)
  const [activeBoardId,setActiveBoardId] =useState("")
  const [isFullScreen, setIsFullScreen] = useState(false);

  const [levels, setLevels] = useState([]);
  useEffect(() => {
    const options = [];
    options.push({ value: "all", label: `${t("all")}` });
    for (let i = 1; i <= downlineHeader?.data?.data?.totalLevel; i++) {
      options.push({ value: i.toString(), label: i.toString() });
    }
    setLevels(options);
  }, [downlineHeader?.data?.data?.totalLevel]);

  const treeData = ApiHook.CallboardTreeList(
    selectedUserId ,
    doubleUser,
    "",
    activeBookId,
    activeBoardId 
  )


   
  const panZoomRef = useRef(null);

  const handleZoomIn = () => {
    panZoomRef.current.zoomIn();
  };

  const handleZoomOut = () => {
    panZoomRef.current.zoomOut();
  };

  const handleZoomReset = () => {
    panZoomRef.current.reset();
  };

  const handleExpand = () => {
    const bodyElement = document.querySelector(
      ".body.genealogy-body.genealogy-scroll"
    );

    const handleFullscreenChange = () => {
      if (
        document.fullscreenElement ||
        document.mozFullScreenElement ||
        document.webkitFullscreenElement ||
        document.msFullscreenElement
      ) {
        setIsFullScreen(true);
      } else {
        setIsFullScreen(false);
      }
    };

    if (!isFullScreen) {
      if (bodyElement.requestFullscreen) {
        bodyElement.requestFullscreen();
      } else if (bodyElement.mozRequestFullScreen) {
        bodyElement.mozRequestFullScreen();
      } else if (bodyElement.webkitRequestFullscreen) {
        bodyElement.webkitRequestFullscreen();
      } else if (bodyElement.msRequestFullscreen) {
        bodyElement.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }

    // Listen for changes to fullscreen state
    document.addEventListener("fullscreenchange", handleFullscreenChange);
    document.addEventListener("mozfullscreenchange", handleFullscreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullscreenChange);
    document.addEventListener("msfullscreenchange", handleFullscreenChange);
  };

  const levelChangehandler = (data) => {
    setselectedLvel(data?.value);
  };

  const SearchHandler = () => {
    setlevel(selectedLevel);
    props.setCurrentPage(1);
  };

  const resetHandler = () => {
    setlevel("all");
    setselectedLvel("all");
  };
  let startPage = 1;
  const toNextPage = () => {
    if (
      downlineMember.data.data.currentPage < downlineMember.data.data.totalPages
    ) {
      props.setCurrentPage(downlineMember.data.data.currentPage + 1);
    }
  };

  const toLastPage = () => {
    props.setCurrentPage(downlineMember.data.data.totalPages);
  };
  const toPreviousPage = () => {
    if (downlineMember.data.data.currentPage > startPage) {
      props.setCurrentPage(downlineMember.data.data.currentPage - 1);
    }
  };

  const toFirstPage = () => {
    props.setCurrentPage(startPage);
  };
  const handleItemsPerPageChange = (event) => {
    const selectedValue = parseInt(event.target.value);
    setItemsPerPage(selectedValue);
    props.setCurrentPage(1);
  };
  const setActiveBooks = (bookId)=>{
  setActivebookId(bookId)
  setActiveBoardId("")
  }
  const backToParent = () => {
    setSelectedUserId("");
    setDoubleUser("");
    setSearchUsername("");
    dispatch(disableBackToParent());
  };
 

  return (
    <>
      <div className="ewallet_table_section">
   
        <div className="ewallet_table_section_cnt">
          <div className="filter_Section">
            <div className="row justify-content-between align-items-center">
              <div className="col-md-12">
                <div className="right_search_div d-flex gap-1">       
                  {treeData?.data?.data?.bookData?.map((treeObj,index)=>(
                    <div className="downMembBg" onClick={()=>setActiveBooks(treeObj.id)} key={treeObj.id}>
                    <div className="card1" style={{
                      backgroundColor: treeObj.id== activeBookId ? "#954cea":"#f2f8f9",
                       color: treeObj.id== activeBookId ? "#ffff":""
                    }}>
                      <p>{treeObj.name}</p>
                    </div>
                  </div>
                  ))}            
                </div>
              </div>
            </div>

            <div className="row justify-content-between align-items-center oira_accounts">
              <div className="col-md-12">
                <div className="right_search_div d-flex gap-1"> 
                {treeData?.data?.data?.oiraCounts?.map((treeObj,index)=>(           
                  <div className="downMembBgOira" onClick={()=>setActiveBoardId(treeObj.boardId)} key={treeObj.boardId}  style={{
                      backgroundColor: treeObj.boardId== activeBoardId ? "#954cea":"#f2f8f9",
                       color: treeObj.boardId== activeBoardId ? "#ffff":""}}>
                      OIRA - {treeObj.boardId}
                  </div>   
                ))}   
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="tree_view_content_section">
            <div className="body genealogy-body genealogy-scroll">
              <svg
                className="tree__background"
                style={{ height: "100%", width: "100%" }}
              >
                <pattern
                  id="pattern-83098"
                  x="6"
                  y="0"
                  width="10"
                  height="10"
                  patternUnits="userSpaceOnUse"
                >
                  <circle cx="0.4" cy="0.4" r="0.4" fill="#81818a"></circle>
                </pattern>
                <rect
                  x="0"
                  y="0"
                  width="100%"
                  height="100%"
                  fill="url(#pattern-83098)"
                ></rect>
              </svg>

              <div className="genealogy-tree">
               <div className="tree_view_action_btn">
              <a className="btn btn-tree-act" onClick={handleZoomIn}>
                <i className="fa fa-plus"></i>
              </a>
              <a className="btn btn-tree-act" onClick={handleZoomOut}>
                <i className="fa fa-minus"></i>
              </a>
              <a className="btn btn-tree-act" onClick={handleZoomReset}>
                <i className="fa fa-refresh"></i>
              </a>
              <a className="btn btn-tree-act" onClick={handleExpand}>
                <i className="fa fa-expand"></i>
              </a>
            </div> 
                <div id="container">
                  <PanZoom
                    disableScrollZoom
                    disableDoubleClickZoom
                    ref={panZoomRef}
                  >
                    {backEnabled && (
                  <span
                    title="Back to parent"
                    className="parent_back_btn"
                    style={{ marginTop: "17px" }}
                    onClick={backToParent}
                  >
                    <i className="fa fa-angle-left"></i>
                  </span>
                )}
                    <ul >
                      {(treeData.isLoading && props?.selectedUserId === "") ||
                      props.isLoading ? (
                        <>
                          <div className="member-view-box">
                            <div className="member-image">
                              <img
                                src="/images/user-profile.png"
                                alt="Member"
                              />
                              <Loader
                                type="spinner-default"
                                bgColor={"#954cea"}
                                color={"#954cea"}
                                size={25}
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <BoardNodeComponent
                          selectedUserId={selectedUserId}
                          setSelectedUserId={setSelectedUserId}
                          doubleUser={doubleUser}
                          setDoubleUser={setDoubleUser}
                          activeBookId={activeBookId}
                          oiraAccounts={treeData?.data?.data?.oiraAccounts}     
                        />
                      )}
                    </ul>
                  </PanZoom>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BoardViewTable;
