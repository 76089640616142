import DOMPurify from "dompurify";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ApiHook } from "../../../hooks/apiHook";
import { Button, Modal } from "react-bootstrap";
import { ReactMultiEmail } from "react-multi-email";
import TablePagination from "../../Common/table/TablePagination";

const InvitesHistory = () => {
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    const startPage = 1;
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    const InvitesHistory = ApiHook.CallGetInviteHistory(itemsPerPage, currentPage);

    const handleOpenModal = (item) => {
        setCurrentItem(item);
        setIsModalOpen(true);
    }
    const handleCloseModal = () => {
        setIsModalOpen(false);
        setCurrentItem(null);
    }
    const handleItemsPerPageChange = (event) => {
        const selectedValue = parseInt(event.target.value);
        setItemsPerPage(selectedValue);
        setCurrentPage(1);
    }
    const toNextPage = () => {
        if (currentPage < InvitesHistory?.data.totalPages) {
            setCurrentPage(currentPage + 1);
        }
    }
    const toLastPage = () => {
        setCurrentPage(InvitesHistory?.data.totalPages);
    };
    const toPreviousPage = () => {
        if (currentPage > startPage) {
            setCurrentPage(currentPage - 1);
        }
    };
    const toFirstPage = () => {
        setCurrentPage(startPage);
    };
    return (
        <>
            <div className="tab-pane fade active show" id="nav-invite-hostory" role="tabpanel" aria-labelledby="nav-invite-history-tab">
                <h4 className="heading_style_1">{t('invitesHistory')}</h4>
                {InvitesHistory?.data?.totalCount !== 0 ? (
                    <>
                        <div className="emailInviteRow">
                            <div className="emailInviteText">

                                {InvitesHistory?.data?.histories.map((item, index) => (
                                    <>
                                        <div key={index} className="sent-item" onClick={() => handleOpenModal(item)}>
                                            <h4 className="subject-history">{item.subject}</h4>
                                            <p className="message-history">
                                                <span
                                                    dangerouslySetInnerHTML={{
                                                        __html: DOMPurify.sanitize(item.message),
                                                    }}
                                                />
                                            </p>
                                            <p className="message-history">To:
                                                {item.mailId.map((item, index) => (
                                                    <>
                                                        <span key={index}> {item + ','}</span>
                                                    </>
                                                ))}
                                            </p>
                                        </div>
                                    </>

                                ))}
                            </div>
                        </div>
                        <TablePagination
                            startPage={startPage}
                            currentPage={InvitesHistory?.data.currentPage}
                            totalPages={InvitesHistory?.data.totalPages}
                            itemsPerPage={itemsPerPage}
                            handleItemsPerPageChange={handleItemsPerPageChange}
                            toNextPage={toNextPage}
                            toLastPage={toLastPage}
                            toPreviousPage={toPreviousPage}
                            toFirstPage={toFirstPage}
                        />
                    </>
                ) : (
                    // NO Data Placeholder
                    <></>
                )}

            </div>
            {/* modal pop-up*/}
            <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{t('EMAILINVITE')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="mb-3">
                            <label for="recipient-name" className="col-form-label">
                                {t('to')}*
                            </label>
                            <ReactMultiEmail emails={currentItem?.mailId} autoFocus disableOnBlurValidation
                                className="disabled-input"
                                getLabel={(email, index, removeEmail) => {
                                    return (
                                        <div data-tag key={index} className="invite-mails-list-container">
                                            <div data-tag-item className="invite-mails-list-item">{email}</div>
                                        </div>
                                    );
                                }}
                            />
                        </div>
                        <div className="mb-3">
                            <p className="mb-0">{t('multiailPLaceholder')}</p>
                            <label for="message-text" className="col-form-label">
                                {t('subject')}*
                            </label>
                            <input className="form-control" id="message-text" value={currentItem?.subject} disabled />
                        </div>
                        <div className="mb-3">
                            <label for="message-text" className="col-form-label">
                                {t('message')}*
                            </label>
                            <div
                                className="form-control"
                                id="message-text"
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(currentItem?.message)
                                }}
                                style={{
                                    whiteSpace: 'pre-wrap',
                                    minHeight: '100px',
                                    padding: '6px 12px',
                                    border: '1px solid #ced4da',
                                    borderRadius: '4px',
                                    backgroundColor: '#e9ecef',
                                    cursor: 'default'
                                }}
                            />
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        {t('close')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
};

export default InvitesHistory;