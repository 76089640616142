import { error } from "jquery";
import API from "../../api/api"


export const promotionServices = {
    getPromotionDetails: async (type) => {
        return API.get(`get-promotion-details?type=${type}`)
        .then((response) => response)
        .catch((error) => Promise.reject(error));
    },
    sentMailInvite: async (body) => {
        return API.post(`sent-email-invite`, body)
        .then((response) => response)
        .catch((error) => Promise.reject(error));
    },
    getInviteHIstory: async (perPage, page) => {
        return API.get(`get-invite-history?perPage=${perPage}&page=${page}`)
        .then((response) => response)
        .catch((error) => Promise.reject(error));
    }
};