import React from "react";
import DownlineMembersTable from "../../components/Tree/DownlineMembersTable";
import { useState } from "react";
import TreeViewNavbar from "../../components/Tree/TreeViewNavbar";
import BoardViewTable from "../../components/Tree/BoardViewTable";

const BoardView = () => {
  const [currentPage, setCurrentPage] = useState(1);

  return (
    <>
      <TreeViewNavbar menu={"boardView"} />
      <BoardViewTable
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </>
  );
};

export default BoardView;